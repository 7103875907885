export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#b33f90', '#37b08f'],
        home_question: ['#b33f90', '#37b08f'],
        applausometro: ['#E5634A', '#E5448A'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#b33f90', '#37b08f'],
        qna: ['#b33f90', '#37b08f'],
    },
    primary: '#b33f90',
    secondary: '#263238',
    accent: '#b33f90',
    text: '#eceff1',
    background:"#263573",
    poll:{
        default:"rgba(179,63,144,0.49)",
        answered:"#b33f90",
        correct: '#37b08f',
        wrong: '#ff295b'
    }
}